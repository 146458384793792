$circle-width: 0.08em;
$circle-width-hover: 0.04em;
// colors default
$primary-color: #307bbb;
$secondary-color: #ccc;
$bg-color: #f5f5f5;
$primary-color-green: #4db53c;
$primary-color-orange: #dd9d22;
// colors dark skin
$primary-color-dark: #c6ff00;
$secondary-color-dark: #777;
$bg-color-dark: #666;
$primary-color-green-dark: #5fd400;
$primary-color-orange-dark: #e08833;