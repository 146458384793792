// @import "../node_modules/bootstrap/scss/bootstrap.scss";
@import './circle/circle.scss';
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  color: black;
}

html, body, #root {
  width: 100%;
  height: 100%;
  overflow: 'hidden';
}

body {
  background: black;
  overflow: hidden;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  overscroll-behavior: contain;
}

h1, h2, h3, h4, p {
  margin-bottom: 0;
  margin-right: 0;
  margin-top: 0;
  margin-left: 0;
}

a {
  cursor: pointer;
  text-decoration: none;
  &:hover {
    color: rgba($color: #fff, $alpha: 0.5);
    text-decoration: none;
  }
}

.text-selection-diabled {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

.pointer-none {
  pointer-events: none;
}

.pl-50 {
  padding-left: 50px;
}

.title {
  font-family: 'Roboto', sans-serif !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loader {
  position: fixed;
  background: white;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 500;
  top: 0;
  left: 0;
  .loader-text {
    font-size: 160px;
    text-align: center;
    line-height: 1;
    font-family: 'Roboto', sans-serif;
    @media screen and (max-width: 900px) {
      font-size: 130px;
    }
    @media screen and (max-width: 620px) {
      font-size: 100px;
    }
    @media screen and (max-width: 500px) {
      font-size: 85px;
    }
    @media screen and (max-width: 450px) {
      font-size: 70px;
    }
    @media screen and (max-width: 380px) {
      font-size: 60px;
    }
    @media screen and (max-width: 370px) {
      font-size: 55px;
    }
  }
  .loader-bar {
    position: absolute;
    height: 3px;
    bottom: 50px;
    left: 50px;
    span {
      height: 100%;
      background: #282c34;
      display: block;
    }
    @media screen and (max-width: 700px) {
      left: 20px;
      left: 30px;
    }
  }
}

.home-icon {
  position: fixed;
  top: 50px;
  left: 50px;
  z-index: 200;
  cursor: pointer;
  &.black {
    path {
      fill: black;
    }
  }
  @media screen and (max-width: 700px) {
    top: 20px;
    left: 20px;
    width: 120px;
  }
}

.social-medias {
  position: fixed;
  bottom: 30px;
  left: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  z-index: 200;
  cursor: pointer;
  a {
    color: white;
    display: block;
    font-weight: 500;
    font-size: 14px;
    &:nth-of-type(2) {
      margin: 0 10px;
    }
  }
  @media screen and (max-width: 700px) {
    left: 20px;
  }
}

.language-btns {
  position: fixed;
  top: 50px;
  right: 120px;
  z-index: 200;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    color: white;
    line-height: 1;
    cursor: pointer;
    &:first-of-type {
      padding-right: 10px;
      margin-right: 10px;
      border-right: 1px solid white;
    }
  }
  &.black {
    p {
      color: black;
      &:first-of-type {
        border-right: 1px solid black;
      }
    }
  }
  @media screen and (max-width: 700px) {
    top: 20px;
    right: 50px;
  }
}

.menu-icon {
  position: fixed;
  top: 50px;
  right: 50px;
  width: 30px;
  height: 11.5px;
  z-index: 200;
  cursor: pointer;
  @media screen and (max-width: 700px) {
    top: 20px;
    right: 20px;
    width: 27px;
    span {
      height: 1.3px;
    }
  }

  span {
    width: 100%;
    height: 1.5px;
    display: block;
    position: absolute;
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: cubic-bezier(0, 0.75, 0.3, 0.79);
    transition-delay: 0s;

    &:nth-of-type(1) {
      top: 0px;
      right: 10px;
    }

    &:nth-of-type(2) {
      top: 10px
    }

  }

  &.white {
    span {
      background: white;
    }
  }
  &.black {
    span {
      background: black;
    }
  }

  &.opened {
    & span:nth-of-type(1) {
      transform: rotate(45deg);
      top: 10px;
      left: 0px;
    }

    & span:nth-of-type(2) {
      transform: rotate(-45deg);
      // right: 10px; 
      // top: 0px; 
    }

  }

}

.side-menu {
  position: fixed;
  height: 100vh;
  width: 100%;
  top: 0;
  right: 0;
  opacity: 0;
  z-index: 100;
  padding: 0px 100px 0px 50px;
  background: black;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  * {
    color: white;
  }
  @media screen and (min-width: 1400px) {
    padding: 0px 100px 0px 50px;
  }
  @media screen and (max-width: 1100px) {
    padding: 0px 25px 0px 25px;
  }
  @media screen and (max-width: 700px) {
    padding: 0 20px 0 0;
  }
  .contact-info {
    align-self: center;
    padding-bottom: 20px;
    position: relative;
    overflow: hidden;
    width: 250px;
    display: block;
    .email, .phone, .curriculum {
      opacity: 0;
      margin-left: -100px;
      display: block;
      position: relative;
    }
    .phone {
      margin-bottom: 5px;
    }
    .email {
      padding-bottom: 15px;
      margin-bottom: 10px;
      &::before {
        content: "";
        background: white;
        position: absolute;
        bottom: 0;
        left: 0;
        height: 1px;
        width: 0%;
      }
    }
    .curriculum {
      color: rgba($color: #fff, $alpha: 0.7);
      &:hover {
        color: white;
      }
    }
  }
  .contact-info-link {
    position: fixed;
    top: 80%;
    width: 100px;
    left: 50px;
    z-index: 100;
    @media screen and (max-width: 700px) {
      left: 20px;
      top: inherit;
      bottom: 55px;
      font-weight: 300;
      font-size: 16px;
    }
  }
  .menu-container {
    .menu-items {
      width: 65vw;
      // height: 100vh;
      // overflow: hidden;
      // list-style-type: none;
      // padding: 0;
      // margin: 0;
      // position: relative;
      @media screen and (min-width: 1400px) {
        width: 77vw;
      }
      .menu-item {
        text-align: right;
        align-items: center;
        padding-top: 25px;
        right: 0;
        * {
          font-family: 'Roboto', sans-serif !important;
        }
        a {
          span {
            font-size: 10rem;
            font-weight: 600;
            transition: 0.5s;
            // line-height: 1;
            line-height: 0.8;
            display: block;
            // margin-top: 100px;
            // opacity: 0;
            color: rgba($color: white, $alpha: 0.3) !important;
            // transition: 1s;
            &:first-of-type {
              padding-right: 45px;
            }
            &:last-of-type {
              font-size: 15px;
              font-weight: 400;
            }
            @media screen and (max-width: 1100px) {
              &:first-of-type {
                font-size: 8rem;
              }
            }
            @media screen and (max-width: 900px) {
              &:first-of-type {
                font-size: 6.5rem;
              }
            }
            @media screen and (max-width: 700px) {
              color: white !important;
              &:first-of-type {
                font-size: 4rem;
                padding-right: 35px;
              }
              &:last-of-type {
                font-size: 10px;
              }
            }
            @media screen and (min-width: 1400px) {
              font-size: 18rem;
              &:last-of-type {
                font-size: 20px;
              }
            }
          }
          &:hover {
            & span {
              color: white !important;
            }
            & span:first-of-type {
              padding-right: 80px;
            }
          }
        }
        &.down {
          span {
            animation: menuItemAnimationScrollDown 1s 0.1s forwards;
          }
        }
        &.up {
          span {
            animation: menuItemAnimationScrollUp 1s 0.1s forwards;
          }
        }
        &.block {
          span {
            opacity: 1; 
            margin-top: 0px;
          }
        }
        &.up-no-animation {
          span {
            opacity: 1; 
            margin-top: 0px;
          }
        }
      }
    }
  }

  @keyframes menuItemAnimationScrollUp {
    from {
      opacity: 0; 
      margin-top: -200px;
    } to {
      opacity: 1; 
      margin-top: 0px;
    }
  }

  @keyframes menuItemAnimationScrollDown {
    from {
      opacity: 0; 
      margin-top: 200px;
    } to {
      opacity: 1; 
      margin-top: 0px;
    }
  }

  &.opened {
    transition: 0.6s;
    right: 0;
    top: 0;
    opacity: 1;
    .contact-info {
      .phone {
        opacity: 1;
        margin-left: 0px;
        transition: 0.5s 0.7s;
      }
      .email {
        opacity: 1;
        margin-left: 0px;
        transition: 0.5s 0.9s;
        &::before {
          width: 100%;
          transition: 0.5s 1s;
        }
      }
      .curriculum {
        opacity: 1;
        margin-left: 0px;
        transition: 0.5s 0.7s;
      }
    }
  }
  &.closed {
    transition: 0.6s;
    right: -500px;
    top: 0;
    opacity: 0;
    .contact-info {
      &::before {
        width: 0%;
      }
    }
  }
}

.all-content, .session {
  width: 100vw;
  height: 100%;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
}

.home {
  display: flex;
  justify-content: center;
  align-items: center;
  .background-home {
    width: 80%;
    height: 60%;
    max-width: 1485px;
    max-height: 680px;
    position: relative;
    background-image: url('./images/home_background.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @media screen and (max-width: 700px) {
      width: 100%;
      height: 100%;
      background-position-x: 59%;
      max-height: inherit;
    }
    .title {
      position: absolute;
      top: 10%;
      left: -10%;
      z-index: 10;
    }
    .about {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 400px;
      height: 200px;
      z-index: 10;
      h2 {
        font-weight: 400;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          background: white;
          width: 240px;
          height: 2px;
          top: 100%;
          left: -100px;
        }
      }
      .arrow {
        position: absolute;
        left: calc(100% - 55px);
        top: calc(100% - 61px);
      }
    }
    @media screen and (max-width: 1400px) {
      .title {
        width: 600px;
      }
      .arrow {
        width: 100px;
      }
    }
    @media screen and (max-width: 700px) {
      .about {
        width: 100%;
        height: 20%;
        min-height: 140px;
        display: block;
        h2 {
          margin-left: 50%;
          font-size: 18px;
          width: fit-content;
          position: absolute;
          right: 20px;
          bottom: 115px;
          &::before {
            left: -70px;
            width: 120%;
          }
        }
      }
      .title {
        width: 100%;
        bottom: 150px;
        top: inherit;
        left: 20px;
        height: 100px;
        padding-left: 0;
      }
      .arrow {
        width: 48px;
        height: 48px;
        right: 35px;
        bottom: 30px;
        left: inherit !important;
        top: inherit !important;
      }
    }
  }
}

.gallery {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100vh;
  & * {
    color: white;
  }
  .image {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-right: 250px;
    pointer-events: none;
    &:last-of-type {
      margin-right: 0;
    }
    img {
      width: 100%;
      position: relative;
    }
    &.ver1 {
      width: 450px;
      // height: 250px;
    }
    &.ver2 {
      width: 300px;
      // height: 250px;
    }
  }
  .title {
    font-size: 160px;
    position: fixed;
    top: 50vh;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    @media screen and (max-width: 900px) {
      font-size: 130px;
    }
    @media screen and (max-width: 620px) {
      font-size: 100px;
    }
    @media screen and (max-width: 500px) {
      font-size: 85px;
    }
    @media screen and (max-width: 450px) {
      font-size: 70px;
    }
    @media screen and (max-width: 380px) {
      font-size: 60px;
    }
    @media screen and (max-width: 370px) {
      font-size: 55px;
    }
  }
}

.counter {
  position: fixed;
  bottom: 30px;
  right: 50px;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  * {
    color: white;
    margin: 0;
    padding: 0;
  }
  .progress-bar {
    width: 100px;
    height: 1.5px;
    background: rgba($color: #ffffff, $alpha: 0.1);
    margin-right: 15px;
    .bar {
      transition: 0.5s;
      height: 100%;
      background: white;
    }
  }
  p {
    font-size: 13px;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
  }
  @media screen and (max-width: 700px) {
    right: 20px;
  }
}

// .projects {
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   height: 100vh;
  .project-listed {
    // width: 600px;
    // height: 250px;
    // margin: 0px 100px;
    // transition: 0.5s;
    opacity: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    // overflow: hidden;
    cursor: pointer;
    .project-content {
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .project-bg {
      width: 100%;
      height: 100%;
      position: relative;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.5s;
      opacity: 1;
      pointer-events: none;
      img {
        width: 100%;
        position: relative;
        z-index: -1;
        @media screen and (max-width: 700px) {
          min-height: 100%;
          // width: auto;
        }
      }
    }
    .title {
      width: 700px;
      fill: white;
      position: absolute;
      top: 80%;
      right: 20%;
      transform: translate(0%, -50%);
      margin: 0;
      z-index: 100;
      opacity: 0;
      transition: 0.5s;
      @media screen and (max-width: 700px) {
        width: 100%;
        left: 10px;
      }
      text {
        font-family: 'Roboto', sans-serif !important;
        height: 200px;
        transition: 0.2s;
        fill: white;
        stroke-width: 0;
      }
      &.selected {
        top: 50%;
        opacity: 1;
      }
    }
    & * {
      color: white;
    }
    &.not-selected, &:hover {
      .title {
        text {
          fill: none;
          stroke-width: .015;
        }
      }
      .project-bg {
        width: 95%;
        height: 95%;
        opacity: 0.4;
      }
    }
  }
// }

.selected-project {
  position: fixed;
  top: 50px;
  left: 300px;
  color: white;
  z-index: 1000;
  font-weight: 400;
  line-height: 1;
  margin: 0;
  border-left: 1px solid white;
  padding-left: 20px;
  font-family: 'Roboto', 'san-sarif';
  &.black {
    border-left: 1px solid black !important;
    color: black !important;
  }
  @media screen and (max-width: 700px) {
    top: 20px;
    left: 150px;
    padding-left: 10px;
    font-size: 16px;
    line-height: 18px;
    margin-top: 1px;
  }
}

.all-projects-label {
  position: fixed;
  bottom: 30px;
  left: 150px;
  color: white;
  z-index: 10;
  margin: 0;
  cursor: pointer;
  font-weight: 300;
  font-size: 16px;
  @media screen and (max-width: 700px) {
    bottom: 30px;
    left: 20px;
  }
}

.projects-types {
  position: fixed;
  bottom: 30px;
  right: 50px;
  z-index: 10;
  * {
    color: white;
    margin: 0;
    padding: 0;
  }
  @media screen and (max-width: 700px) {
    right: 20px;
    ul {
      li {
        font-size: 14px;
      }
    }
  }
  ul {
    li {
      list-style-type: none;
      display: inline-block;
      cursor: pointer;
      margin-right: 10px;
      &:last-of-type {
        margin-right: 0px;
      }
      &.selected {
        font-weight: bold;
      }
    }
  }
}

.project-details {
    background: white;
    position: fixed;
    top: 100%;
    left: 0;
    opacity: 0;
    z-index: 200;
    &.animate {
      animation: toggleProject 0.7s forwards;
    }
    &.animateReload {
      animation: reloadProject 0.7s forwards;
    }
    &.reverse {
      animation: toggleProjectReverse 0.7s forwards;
    }

    .project-bar {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 10;
      height: 5px;
      transition: 0.3s;

      &.shown {
        opacity: 1;
      }

      &.hidden {
        opacity: 0;
      }

      .bar {
        height: 100%;
        background: black
      }
    }

    .close-icon {
      width: 30px;
      height: 30px;
      position: fixed;
      top: 50px;
      right: 50px;
      color: black;
      font-size: 20px;
      cursor: pointer;
      @media screen and (max-width: 700px) {
        top: 20px;
        right: 20px;
        width: 27px;
      }

      span {
        width: 100%;
        height: 1.5px;
        display: block;
        position: absolute;
        background: black;
    
        &:nth-of-type(1) {
          transform: rotate(45deg);
          top: 11px;
        }
    
        &:nth-of-type(2) {
          transform: rotate(-45deg);
          right: 0px; 
          top: 11px; 
        }
    
      }
    }

    .opened-projects-content {
      transition: all 0s cubic-bezier(0, 0.98, 0.33, 0.93) 0s;
      position: fixed;
      display: flex;
      flex-direction: row;
      padding: 20vh 0;
      height: 100vh;

      .opened-project {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: max-content;
        transition: 1s;
        opacity: 0;
        height: 100%;
        &.selected {
          opacity: 1;
        }
        &.non-selected {
          opacity: 0;
        }
        
        .next {
          height: 100%;
          display: flex;
          align-items: center;
          overflow: hidden;
          h1 {
            font-size: 180px;
            font-weight: bold;
          }
        }

      }

      .project-img {
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          height: 100%;
          // transition: 0.5s;
          // &:hover {
          //   width: calc(100% + 20px);
          // }
        }

        &.mr {
          margin-right: 25px;
        }

        &.mr-last {
          margin-right: 75px;
        }

        &.img-lg {
          height: 100%;
          // width: 53vw;
          // img {
          //   min-width: 120%;
          // }
        }

        &.img-md {
          height: 100%;
          // width: 20vw;
          // img {
          //   width: auto;
          //   min-height: 100%;
          // }
        }

        &.imgs-sm {
          height: 100%;
          width: 30vw;
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: column;

          div {
            width: 100%;
            height: 50%;

            &:first-of-type {
              margin-bottom: 20px;
            }
            &:last-of-type {
              margin-top: 20px;
            } 

            img {
              width: auto;
              min-height: 100%;
            }
          }
        }

      }

      .project-text {
        // width: 47vw;
        width: 650px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: left;
        padding: 50px 85px;
        p {
          color: #525252;
          line-height: 1.8;
        }
      }

      @media screen and (max-width: 700px) {
        .close-icon {
          top: 20px;
          right: 20px;
        }
        .project-img {
          &.img-lg {
            // width: 100vw;
            margin: 0 !important;
            padding: 0 20px !important;
            div {
              padding: 0 !important;
            }
          }
          &.img-md, &.imgs-sm {
            // width: 100vw;
            margin: 0 !important;
            padding: 0 20px !important;
            div {
              padding: 0 !important;
            }
          }
        }
        .project-text {
          width: 100vw;
          padding: 30px 20px;
        }
      }

    }

}

.about {
  display: flex;
  justify-content: center;
  align-items: center;
  * {
    color: white;
  }
  .content {
    width: 80vw;
    max-width: 1200px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    @media screen and (max-width: 700px) {
      width: 77vw;
    }
    .content-item {
      transition: 0.7s;
      width: 100%;
      &.title {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &.multi-column {
        display: flex;
        justify-content: space-between;
      }
      .col-6 {
        width: 46%;
        float: left;
        position: relative;
        padding: 50px 0px 0px 0px;
        h1 {
          position: absolute;
          top: -25px;
          left: 0px;
          z-index: -1;
          color: rgba($color: #fff, $alpha: 0.1);
          font-size: 80px;
          margin: 0;
          @media screen and (max-width: 900px) {
            top: -10px;
            font-size: 65px;
          }
          @media screen and (max-width: 700px) {
            top: -10px;
            font-size: 35px;
          }
        }
        p {
          margin-left: 40px;
          @media screen and (max-width: 700px) {
            margin-left: 0px;
          }
        }
      }
      h2 {
        margin-bottom: 3px;
        @media screen and (max-width: 700px) {
          line-height: 1.2;
        }
      }
      p {
        font-size: 18px;
        line-height: 1.7;
        @media screen and (max-width: 700px) {
          font-size: 13px;
          line-height: 1.5;
        }
      }
      .separator-p {
        margin-bottom: 30px;
      }
      .separator-li {
        margin-bottom: 20px;
      }
      .title {
        font-size: 160px;
        text-align: center;
        font-family: 'Roboto', sans-serif;
        &.small {
          font-size: 120px;
          line-height: 1;
          @media screen and (max-width: 900px) {
            font-size: 130px;
          }
          @media screen and (max-width: 620px) {
            font-size: 100px;
          }
          @media screen and (max-width: 500px) {
            font-size: 85px;
          }
          @media screen and (max-width: 450px) {
            font-size: 70px;
          }
          @media screen and (max-width: 380px) {
            font-size: 60px;
          }
          @media screen and (max-width: 370px) {
            font-size: 55px;
          }
        }
        @media screen and (max-width: 900px) {
          font-size: 130px;
        }
        @media screen and (max-width: 620px) {
          font-size: 100px;
        }
        @media screen and (max-width: 500px) {
          font-size: 85px;
        }
        @media screen and (max-width: 450px) {
          font-size: 70px;
        }
        @media screen and (max-width: 380px) {
          font-size: 60px;
        }
        @media screen and (max-width: 370px) {
          font-size: 55px;
        }
      }
      &.up {
        animation: upAnimation 0.7s forwards;
      }
      &.down {
        animation: downAnimation 0.7s forwards;
      }
    }
  }
  .download-curriculum {
    position: fixed;
    bottom: 30px;
    right: 50px;
    z-index: 100;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    @media screen and (max-width: 700px) {
      right: 20px;
      h3 {
        font-size: 13px !important;
      }
      svg {
        width: 23px;
        height: 23px;
      }
    }
    h3 {
      font-size: 16px;
      font-weight: 300;
      margin-right: 10px;
    }
    svg {
      width: 34px;
      height: 34px;
    }
    * {
      transition: 0.2s;
    }
    &:hover {
      h3 {
        color: rgba($color: #fff, $alpha: 0.5);
      }
      svg {
        * {
          stroke: rgba($color: #fff, $alpha: 0.5);
        }
      }
    }
  }
  .line {
    position: fixed;
    bottom: 10vh;
    right: 50%;
    transform: rotateX(-50%);
    z-index: 10;
    width: 2px;
    height: 80px;
    background: rgba($color: #fff, $alpha: 0.2);
    overflow: hidden;
    @media screen and (max-width: 700px) {
      height: 60px;
      bottom: 12vh;
    }
    .line-content {
      width: 2px;
      height: 60px;
      background: white;
      position: absolute;
      animation: indicateScrollDown 5s forwards infinite;
    }
  }
  .indexes {
    position: fixed;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    z-index: 10;
    @media screen and (max-width: 700px) {
      right: 10px;
      .index {
        .c100 {
          width: 0.5em;
          height: 0.5em;
        }
        .circle {
          width: 4px !important;
          height: 4px !important;
        }
      }
    }
    .index {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 5px;
      position: relative;
      cursor: pointer;
      h3 {
        margin: 0;
        position: absolute;
        width: max-content;
        transition: 0.5s;
        right: calc(100% + 15px);
        font-family: 'Roboto', sans-serif;
      }
      &.not-current-index {
        @media screen and (max-width: 700px) {
          .circle {
            background: rgba($color: #ffffff, $alpha: 0.3) !important;
          }
        }
      }
      // &.current-index {
      //   .index-icon {
      //     // border: 1px solid white;
      //     // .border-right {
      //     //   border: 1px solid white;
      //     // }
      //     // .border-left {
      //     //   border: 1px solid white;
      //     // }
      //   }
      // }
      .index-icon {
        cursor: pointer;
      //   width: 5px;
      //   height: 5px;
      //   display: block;
      //   border-radius: 50%;
      //   border: 1px;
      //   margin-left: 10px;
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;
      //   position: relative;
      //   margin: 10px 0;
        .circle {
          background: white;
          display: block;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          position: absolute;
          top: calc(50% - 3px);
          left: calc(50% - 3px);
          cursor: pointer;
        }
      }
      &:last-of-type {
        margin-bottom: 0px;
        .c100 {
          margin: 0 0.1em 0 0;
        }
      }
    }
  }
}

.downloads {
  .download {
    padding: 0px 0px 20px 0px;
    position: relative;
    .download-box {
      background: #737373;
      height: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      .link {
        color: white;
        position: absolute;
        line-height: 1;
      }
      .open-link {
        left: 15px;
        bottom: 15px;
        z-index: 1;
      }
      .download-link {
        right: -35px;
        bottom: -15px;
        font-size: 35px;
        font-weight: 600;
        z-index: 1;
        @media screen and (max-width: 700px) {
          right: 15px;
          bottom: 15px;
        }
      }
      .title {
        // width: 350px;
        position: absolute;
        left: 15px;
        top: 14px;
        // left: 50%;
        // transform: translate(-50%, -50%);
        // margin: 0;
        // z-index: 100;
        // opacity: 0;
        transition: 0.5s;
        // top: 50%;
        // opacity: 1;
        z-index: 1;
        text {
          font-family: 'Roboto', sans-serif !important;
          // height: 200px;
          // transition: 0.2s;
          fill: white;
          stroke-width: 0;
          // fill: none;
          // stroke-width: 0.02;
        }
      }
      // &:hover {
      //   .title {
      //     text {
      //       fill: none;
      //       stroke-width: 0.02;
      //     }
      //   }
      // }
      .img-container {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          position: relative;
          max-width: 120%;
        }
      }
    }
  }
}

.contact-info {
  display: flex;
  justify-content: center;
  align-items: center;
  * {
    color: white;
  }
  div {
    align-self: center;
    padding-bottom: 20px;
    position: relative;
    overflow: hidden;
    width: 250px;
    .email, .phone, .curriculum {
      opacity: 0;
      margin-left: -100px;
      display: block;
      position: relative;
    }
    .phone {
      margin-bottom: 5px;
      animation: contactInfoAnimation 0.7s 0s  forwards;
    }
    .email {
      padding-bottom: 15px;
      margin-bottom: 10px;
      &::before {
        content: "";
        background: white;
        position: absolute;
        bottom: 0;
        left: 0;
        height: 1px;
        width: 0%;
        animation: emailBeforeAnimation 1s 0s forwards;
      }
      animation: emailAnimation 0.9s 0s  forwards;
    }
    .curriculum {
      animation: contactInfoAnimation 0.7s forwards;
      color: rgba($color: #fff, $alpha: 0.7);
      &:hover {
        color: white;
      }
    }
  }
}

.not-found {
  * {
    color: white;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  .content {
    width: 600px;
    max-width: 90vw;
    height: inherit;
  }
  .arrow {
    width: 60px;
    height: 60px;
    transform: rotateZ(180deg);
    position: absolute;
    top: 101%;
    left: 38%;
  }
  div {
    position: relative;
    .title {
      font-size: 160px;
      @media screen and (max-width: 900px) {
        font-size: 130px;
      }
      @media screen and (max-width: 620px) {
        font-size: 100px;
      }
      @media screen and (max-width: 500px) {
        font-size: 85px;
      }
      @media screen and (max-width: 450px) {
        font-size: 70px;
      }
      @media screen and (max-width: 380px) {
        font-size: 60px;
      }
      @media screen and (max-width: 370px) {
        font-size: 55px;
      }
    }
    div {
      position: absolute;
      width: 350px;
      .text {
        width: 100%;
      }
      .arrow {
        width: 60px;
        height: 60px;
        transform: rotateZ(180deg);
      }
    }
  }
}

@keyframes contactInfoAnimation {
  from { 
    opacity: 0;
    margin-left: -100px;
  }
  to { 
    opacity: 1;
    margin-left: 0px;
  }
}

@keyframes emailAnimation {
  from { 
    opacity: 0;
    margin-left: -100px;
  }
  to { 
    opacity: 1;
    margin-left: 0px;
  }
}

@keyframes emailBeforeAnimation {
  from {
    width: 0%;
  } to {
    width: 100%;
  }
}

.slider-container {
  overflow: hidden;
  display: flex;
  align-items: center;
  .slider {
    position: relative;
    height: 100%;
    margin-bottom: 10vh;
    &:last-of-type {
      margin-bottom: 0px;
    }
  }
}

.projects-loader {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  z-index: 100;
  .line {
    width: 200px;
    height: 5px;
    background: rgba($color: rgb(179, 179, 179), $alpha: 0.2);
    overflow: hidden;
    position: relative;
    .line-content {
      width: 160px;
      height: 5px;
      background: black;
      position: absolute;
      animation: indicateScrollRight 1.5s forwards infinite;
    }
  }
}

@keyframes reloadProject {
  from { 
    opacity: 0; 
  }
  to { 
    opacity: 1; 
  }
}

@keyframes toggleProject {
  from { 
    opacity: 0; 
    top: 100%; 
  }
  to { 
    opacity: 1; 
    top: 0; 
  }
}

@keyframes toggleProjectReverse {
  from { 
    opacity: 1; 
    top: 0; 
  }
  to { 
    opacity: 0; 
    top: 100%; 
  }
}

@keyframes upAnimation {
  from {
    opacity: 0;
    margin-top: 50px;
  } to {
    opacity: 1;
    margin-top: 0px;
  }
}

@keyframes downAnimation {
  from {
    opacity: 0;
    margin-top: 50px;
  } to {
    opacity: 1;
    margin-top: 0px;
  }
}

@keyframes indicateScrollDown {
  0% { 
    top: -60px;
  }
  20% { 
    top: 100%;
  }
  100% { 
    top: 100%;
  }
}

@keyframes indicateScrollRight {
  0% { 
    left: -160px;
  }
  70% { 
    left: 100%;
  }
  100% { 
    left: 100%;
  }
}
